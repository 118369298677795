import React from "react";
import { deleteHighlight } from "../../services/api";

function HighLightPost({ posts, onEdit }) {
  const remove = async (i) => {
    try {
      const response = await deleteHighlight(i._id);
      if (response.status) {
        alert("Highlight deleted successfully");
        window.location.reload();
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.error("Error deleting highlight:", error);
      alert("Error deleting highlight");
    }
  };

  return (
    <>
      {posts &&
        posts.map((i, index) => (
          <tr key={i._id}>
            <td>
              <span>{index + 1}</span>
            </td>
            <td>
              <span>{i.category}</span>
            </td>
            <td>
              <span>{i.subCategory}</span>
            </td>
            <td>
              <span>{i.subsubCategory}</span>
            </td>
            <td>
              <span>{i.product}</span>
            </td>
            <td>
              <div className="d-flex flex-wrap gap-1">
                {i.labels.map((label, idx) => (
                  <span key={idx} className="badge bg-primary">
                    {label}
                  </span>
                ))}
              </div>
            </td>
            <td>
              <button
                className="btn btn-lg btn-primary mx-2 rounded"
                onClick={() => onEdit(i)}
              >
                Edit
              </button>
              <button
                className="btn btn-lg btn-danger mx-2 rounded"
                onClick={() => remove(i)}
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
    </>
  );
}

export default HighLightPost;

