import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Carousels, UploadFile } from "../../services/api";
import * as yup from "yup";

function CarouselForm() {
  const validate = yup.object({
    carouselnum: yup.string().required("Required"),
    cauimg: yup.mixed().when("carouselnum", {
      is: (val) => val !== "7",
      then: yup.mixed().required("Required"),
      otherwise: yup.mixed().nullable(),
    }),
    banner: yup.mixed().when("carouselnum", {
      is: "7",
      then: yup.mixed().required("Required"),
      otherwise: yup.mixed().nullable(),
    }),
  });
  

  const setBanner = (imageUrl) => {
    console.log("Banner set with URL:", imageUrl);
    // You can update state or call an API if needed
  };

  return (
    <>
      <Formik
        initialValues={{
          carouselnum: "",
          cauimg: "",
          banner: "",
        }}
        validationSchema={validate}
        onSubmit={async (values, actions) => {
          try {
            const data = new FormData();
            let image;

            if (values.carouselnum === "7" && values.banner) {
              data.append("name", values.banner.name);
              data.append("file", values.banner);
              image = await UploadFile(data);
              values.banner = image.data;
              setBanner(image.data); // Call setBanner after upload
            } else if (values.cauimg) {
              data.append("name", values.cauimg.name);
              data.append("file", values.cauimg);
              image = await UploadFile(data);
              values.cauimg = image.data;
            }

            let dataresponse = await Carousels(values);

            if (!dataresponse.status) {
              alert("Something went wrong", dataresponse);
            } else {
              alert("Successfully created", dataresponse.status);
              window.location.reload();
            }
          } catch (error) {
            alert("Error in processing form", error);
          }

          actions.resetForm();
        }}
      >
        {(formik) => (
          <Form>
            <br />
            <label>Carousel Number</label>
            <Field
              as="select"
              className={`form-control shadow-none ${
                formik.touched.carouselnum &&
                formik.errors.carouselnum &&
                "is-invalid"
              }`}
              name="carouselnum"
              onChange={(e) => {
                formik.setFieldValue("carouselnum", e.target.value);
                if (e.target.value === "7") {
                  formik.setFieldValue("cauimg", "");
                }
              }}
            >
              <option value="">Select Carousel</option>
              <option value="1">Carousel 1</option>
              <option value="2">Carousel 2</option>
              <option value="3">Carousel 3</option>
              <option value="4">Carousel 4</option>
              <option value="5">Carousel 5</option>
              <option value="6">Carousel 6</option>
              <option value="7">Banner</option>
            </Field>
            <ErrorMessage name="carouselnum" component="div" className="error" />

            <br />
            <label>Carousel Image</label>
            <input
              type="file"
              accept="image/*"
              className={`form-control shadow-none mt-2 ${
                formik.touched.cauimg && formik.errors.cauimg && "is-invalid"
              }`}
              name="cauimg"
              placeholder="Image"
              onChange={(e) => {
                if (formik.values.carouselnum === "7") {
                  formik.setFieldValue("banner", e.target.files[0]);
                } else {
                  formik.setFieldValue("cauimg", e.target.files[0]);
                }
              }}
            />
            <ErrorMessage component="div" name="cauimg" className="error" />

            <br />
            <input
              type="submit"
              className="btn mt-2 rounded-3 w-20 btn-lg btn-outline-secondary btn-dark"
              value="Submit"
            />
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CarouselForm;
