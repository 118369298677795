import React, { useEffect, useState } from "react";
import { getCartbtntcheckout } from "../../services/api";
import Pagination from "../categories/categories/Pagination";
import Footer from "../footer/Footer";
import CartsPosts from "./Cartpost";

function Cart() {
  const [ord, setOrd] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(25);
  const [searchedvalue, setSearchedValue] = useState("");
  const [noResults, setNoResults] = useState(false);
  const [sortOrder, setSortOrder] = useState(
    localStorage.getItem("ordersSortOrder") || "desc"
  );
  const [showImportedOnly, setShowImportedOnly] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        let dat = await getCartbtntcheckout();
        if (dat && dat.length) {
          setOrd(dat);
        } else {
          setOrd([]);
          alert("No data found");
        }
      } catch (error) {
        alert("Something went wrong in getting data.");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem("ordersSortOrder", sortOrder);
  }, [sortOrder]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchedvalue.trim()) {
      try {
        let dat = await getCartbtntcheckout(searchedvalue);
        if (dat && dat.length) {
          setOrd(dat);
          setNoResults(false);
        } else {
          setOrd([]);
          setNoResults(true);
        }
      } catch (error) {
        alert("Error fetching search results.");
      }
    }
  };

  const handleClearSearch = async () => {
    setSearchedValue("");
    try {
      let dat = await getCartbtntcheckout();
      setOrd(dat || []);
      setNoResults(false);
    } catch (error) {
      alert("Error fetching data");
    }
  };
console.log(ord , "orderssss")
  // Sorting before filtering
  const handleSortClick = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
  
    // Sort orders immediately and update state
    const sortedOrders = [...ord].sort((a, b) => {
      const dateA = new Date(a.cart.cart[0].addedAt).getTime();
      const dateB = new Date(b.cart.cart[0].addedAt).getTime();
      return newSortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
  
    setOrd(sortedOrders);
    setSortOrder(newSortOrder);
  };
  
  
  console.log(ord[0],"chek")
  const filteredOrders = showImportedOnly
    ? ord.filter((order) => order.cart?.cart[0]?.item?.imported)
    : ord;

  const totalPages = Math.ceil(filteredOrders.length / postsPerPage);
  const pageNumbers = [...Array(totalPages + 1).keys()].slice(1);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredOrders.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <h4 className="page-title">Cart</h4>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title mb-4">Cart List</h4>

                    {/* Search Bar */}
                    <div className="row align-items-center">
                      <div className="col-md-8 mt-3">
                        <form className="d-flex mb-2" onSubmit={handleSearch}>
                          <input
                            className="form-control me-2"
                            type="search"
                            value={searchedvalue}
                            placeholder="Search Phone Number"
                            onChange={(e) => setSearchedValue(e.target.value)}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-danger"
                            onClick={handleClearSearch}
                          >
                            <i className="fa fa-times"></i>
                          </button>

                          <button className="btn btn-dark ms-2" type="submit">
                            Search
                          </button>
                        </form>
                      </div>

                      {/* Sort and Toggle */}
                      <div className="col-md-4 mt-3 d-flex justify-content-end gap-3">
                      <button className="btn btn-outline-primary" onClick={handleSortClick}>
  {sortOrder === "asc" ? "Oldest First" : "Newest First"}
</button>



                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="importedToggle"
                            checked={showImportedOnly}
                            onChange={() => setShowImportedOnly((prev) => !prev)}
                          />
                          <label className="form-check-label ms-2" htmlFor="importedToggle">
                            Show Imported Only
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* Table */}
                    <div className="table-responsive">
                      {noResults ? (
                        <div className="text-center mt-4">
                          <p>No user with this phone number.</p>
                        </div>
                      ) : (
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>Sr No</th>
                              <th>Full Name</th>
                              <th>Phone No</th>
                              <th>Products</th>
                              <th>Quantity</th>
                              <th>Created Date</th>
                              <th>Details</th>
                              <th>Status</th>
                              <th>Response</th>
                              <th>Add response</th>
                            </tr>
                          </thead>
                          <tbody>
                            <CartsPosts posts={currentPosts} />
                          </tbody>
                        </table>
                      )}
                    </div>

                    {/* Pagination */}
                    <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={totalPages}
                      paginate={setCurrentPage}
                      currentPage={currentPage}
                      pageNumbers={pageNumbers}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Cart;
