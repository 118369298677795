import React from "react";

function CorporatePosts({ posts, onRoleChange }) {
  return (
    <>
      {posts &&
        posts.map((i, index) => (
          <tr key={i._id}>
            <td>{index + 1}</td>
            <td>{i.username}</td>
            <td>{i.fullname}</td>
            <td>{i.phoneno}</td>
            <td>{i.email}</td>
            <td>{i.gst}</td>
            <td>{i.pan}</td>
            <td>{i.orgname}</td>
            <td>{i.billingaddress1}</td>
            <td>{i.shippingaddress1}</td>
            <td>
              <span className={`badge ${i.corporate ? 'bg-success' : 'bg-warning'}`}>
                {i.corporate ? 'Approved' : 'Pending'}
              </span>
            </td>
            <td>
              {i.corporate ? (
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => onRoleChange(i._id, true)}
                >
                  Make Normal User
                </button>
              ) : (
                <button
                  className="btn btn-success btn-sm"
                  onClick={() => onRoleChange(i._id, false)}
                >
                  Approve Corporate
                </button>
              )}
            </td>
          </tr>
        ))}
    </>
  );
}

export default CorporatePosts;
