import React, { useState } from "react";
import { deleteSeo } from "../../services/api";

function SeoPost({ posts, onEdit }) {
  const [modalData, setModalData] = useState(null);

  const remove = async (i) => {
    let dat = await deleteSeo(i._id);

    if (dat.status) {
      alert("DELETED", dat.data);
      window.location.reload();
    } else {
      alert("Something went wrong");
      window.location.reload();
    }
  };

  return (
    <>
      {posts &&
        posts.map((i, index) => {
          console.log(i);
          return (
            <tr key={i._id}>
              <td>
                <span>{index + 1}</span>
              </td>
              <td>
                <span>{i.category}</span>
              </td>
              <td>
                <span>{i.subCategory}</span>
              </td>
              <td>
                {/* <span>{i.subsubCategory}</span> */}
                <span>{i.old_url}</span>
              </td>
              <td>
                <span>{i.product}</span>
              </td>
              <td>
                <span>
                  <button
                    className="btn btn-info mx-2"
                    onClick={() => setModalData(i)}
                  >
                    View Details
                  </button>
                </span>
              </td>
              <td>
                <button
                  className="btn btn-primary mx-2"
                  onClick={() =>
                    onEdit({
                      ...i,
                      url_structure: i.url_structure || i.old_url || "",
                    })
                  }
                >
                  Edit
                </button>
                <button
                  className="btn btn-danger mx-2"
                  onClick={() => remove(i)}
                >
                  Delete
                </button>
              </td>
            </tr>
          );
        })}

      {/* SEO Details Modal */}
      {modalData && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-white">SEO Details</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setModalData(null)}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body text-white">
                <p>
                  <strong>Meta Title:</strong> {modalData.meta_title}
                </p>
                <p>
                  <strong>Meta Description:</strong>{" "}
                  {modalData.meta_description}
                </p>
                <p>
                  <strong>Meta Keywords:</strong> {modalData.meta_keywords}
                </p>
                <p>
                  <strong>New URL:</strong>{" "}
                  {modalData.url_structure || modalData.old_url}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setModalData(null)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SeoPost;
