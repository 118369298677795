import React, { useState } from "react";
import { orderput } from "../../services/api";
import moment from "moment";

function OrdersPosts({ posts }) {
  const [sta, setSta] = useState("PENDING");
  const [orderDetails, setOrderDetails] = useState({});

  const handleSubmit = async (orders, productid, productindex) => {
    try {
      console.log("Submitting order update:", orders, productid, productindex, orders.user)
      console.log("Submitting order update:", orders.user, orders.products[productindex]._id, orders.order._id,orders.products[productindex].status)
      let response = await orderput(
        orders.user,
        orders.products[productindex]._id,
        orders.order._id,
        orders.products[productindex].status
      );
      console.log("Submitted order update:", orders.user, orders.products[productindex]._id, orders.order._id, orders.products[productindex].status)
      console.log("API Response:", response);

      if (response.status === 200) {
        alert("SUCCESSFUL");
        // Update the UI dynamically instead of reloading
      }
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const detailsChangeHandler = (p, i, productindex) => {
    setOrderDetails({
      productname: p.productname1,
      category: p.categoryid,
      scategory: p.subcategory,
      emailid: i.order.BillingEmail,
      mobileno: i.order.Mobileno,
      pan: i.order.PANno,
      paymentid: i.order.OrderId,
      DOD: i.order.shippingdetail[productindex].DOD,
      CssDeals: i.order.shippingdetail[productindex].CSS,
      promoCOde: i.order.shippingdetail[productindex].Promo,
      skuid: p.skuid5,
      imported: p.imported,
      dprice: i.order.products[0].finalPrice,
      address: i.user[0]?.shippingaddress1 || "N/A",
      fullName: i.order.FullName,
      billingName: i.order.BillingName,
      bilingaddress: i.order.BillingAddress,
      paymentStatus: i.order.paymentStatus,
      GstNo: i.order.GSTno,
      shippingaddress: i.order.Shippingaddress,
      gst: i.order.shippingdetail[productindex]?.gst || 0,
      shippingCost: i.order.shippingdetail[productindex]?.shippingCost || 0,
      methodOfShipping:
        i.order.shippingdetail[productindex]?.shippingType || "N/A",
      NoOfBoxes: i.order.shippingdetail[productindex]?.boxes?.[0]?.boxes || 0,
      finalPrice: i.order.amount,
      p1: i.order.bankRefNo,
      p2: i.order.cardName,
      p3: i.order.paymentMode,
      p4: i.order.trackingId,
      p5: i.order.paymentStatus,
      p6: i.order.failureMes,
      p7: i.order.statusMes,
    });
  };


  return (
    <>
      {posts?.map((i, index) =>
        i.products.map((p, productindex) => {
          return (
            <tr key={`${index}-${productindex}`}>
              {i.order.paymentStatus && (
                <>
                  <th>{index + 1}</th>
                  <th>{p.productname1}</th>
                  <th>
                    {i.user?._id === i.order?.user._id ? i.user.username : "N/A"}
                  </th>
                  {/* <th>
                    {i.user?._id === i.order?.user._id ? i.user.phoneno : "N/A"}
                  </th> */}

                  <th>{i.order.shippingdetail[productindex]?.quantity || 0}</th>
                  <th>
                    {i.order.shippingdetail[productindex]?.Price /
                      i.order.shippingdetail[productindex]?.quantity || 0}
                  </th>
                  <th>
                    {parseFloat(
                      i.order.shippingdetail[productindex]?.Price || 0
                    ) +
                      parseFloat(
                        ((i.order.shippingdetail[productindex]?.Price || 0) *
                          (i.order.shippingdetail[productindex]?.gst || 0)) /
                        100
                      )}
                  </th>
                  <th>
                    <button className="btn btn-success">
                      {i.order.products.find(
                        (prod) =>
                          prod.vairanceid === p._id || prod.productid === p._id
                      )?.status || "N/A"}
                    </button>
                  </th>
                  <th>
                    <select
                      onChange={(e) => setSta(e.target.value)}
                      className="form-control mx-1"
                    >
                      <option value="PENDING">PENDING</option>
                      <option value="DELIVERED">DELIVERED</option>
                      <option value="RETURN">RETURN</option>
                      <option value="IN TRANSIT">IN TRANSIT</option>
                      <option value="REFUND">REFUND</option>
                      <option value="CANCELLED">CANCELLED</option>
                    </select>
                  </th>
                  <th>
                    <button
                      onClick={() => handleSubmit(i, p, productindex)}
                      className="btn btn-dark btn-sm"
                    >
                      Submit
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#detailsModal"
                      onClick={() => detailsChangeHandler(p, i, productindex)}
                    >
                      Details
                    </button>
                  </th>
                  <th>
                    <input
                      type="checkbox"
                      checked={p.imported}
                      disabled
                    />{" "}
                    {p.imported ? "Yes" : "No"}
                  </th>
                  <th>{moment(i.order.createdAt).format("Do MMMM YYYY")}</th>
                  <th>{moment(i.order.createdAt).format("LTS")}</th>
                </>
              )}
            </tr>
          );
        })
      )}

      {/* Modal */}
      <div
        className="modal fade"
        id="detailsModal"
        tabIndex="-1"
        aria-labelledby="detailsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5>{orderDetails.productname}</h5>
              <button
                type="button"
                className="btn btn-outline-secondary btn-dark"
                data-bs-dismiss="modal"
              >
                <i className="fa fa-times" style={{ color: "white" }}></i>
              </button>
            </div>
            <div className="modal-body"  style={{ color: "white" }} >
              <label>Category: {orderDetails.category}</label><br />
              <label>Sub Category: {orderDetails.scategory}</label><br />
              <label>SKUID: {orderDetails.skuid}</label><br />
              <label>Mobile No: {orderDetails.mobileno}</label><br />
              <label>Email: {orderDetails.emailid}</label><br />
              <label>GST No: {orderDetails.GstNo}</label><br />
              <label>GST: {orderDetails.gst}%</label><br />
              <label>Pan No :{orderDetails.pan}</label><br />
              <label>Full Name: {orderDetails.fullName}</label><br />
              <label>Billing Name: {orderDetails.billingName}</label><br />
              <label>Billing Address: {orderDetails.bilingaddress}</label><br />
              <label>Shipping Address: {orderDetails.shippingaddress}</label><br />
              <label>Payment ID/Order ID: {orderDetails.paymentid}</label><br />
              <label>Final Price: ₹{orderDetails.finalPrice}</label><br />
              <label>DOD: {orderDetails.DOD}</label><br />
              <label>CssDeals: {orderDetails.CssDeals}</label><br />
              <label>promoCOde :{orderDetails.promoCOde} </label><br />
              <label>Free Shipping :{orderDetails.freeShip}{" "}</label><br />
              <label>shipping Cost :{orderDetails.shippingCost}{" "}</label><br />
              <label>methodOfShipping :{orderDetails.methodOfShipping}{" "}</label><br />
              <label>NoOfBoxes :{orderDetails.NoOfBoxes} </label><br />
              <label>Bank Ref No.: {orderDetails.p1}</label><br />
              <label>Card Name: {orderDetails.p2}</label><br />
              <label>Payment Mode: {orderDetails.p3}</label><br />
              <label>Tracking ID: {orderDetails.p4}</label><br />
              <label>Payment Status: {orderDetails.p5}</label><br />
              <label>Failure Message: {orderDetails.p6}</label><br />
              <label>Status Message: {orderDetails.p7}</label>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrdersPosts;
