import React, { useState, useRef, useEffect } from "react";
import Footer from "../footer/Footer";
import JoditEditor from "jodit-react";
import { Form, Formik } from "formik";
import { rrc, rrcget } from "../../services/api";

function RRC() {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    // Fetch existing RRC policy when component mounts
    const fetchRRCPolicy = async () => {
      try {
        const response = await rrcget();
        if (response && response.data && response.data.length > 0) {
          // Get the most recent RRC policy
          const latestPolicy = response.data[0];
          setContent(latestPolicy.rrcpolicy);
        }
      } catch (error) {
        console.error("Error fetching RRC policy:", error);
      }
    };

    fetchRRCPolicy();
  }, []);

  return (
    <div>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 className="page-title">Refund & Cancellation Policy</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title mb-4">RC Policy</h4>

                    <div className="table-responsive">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          rrcpolicy: content
                        }}
                        onSubmit={async (values, actions) => {
                          try {
                            let data = await rrc({
                              rrcpolicy: content,
                            });
                            if (data.status) {
                              alert("SUCCESSFULLY UPDATED");
                            } else {
                              alert("Something went wrong");
                            }
                          } catch (error) {
                            console.error("Error updating RRC policy:", error);
                            alert("Error updating RRC policy");
                          }

                          actions.resetForm();
                          window.location.reload();
                        }}
                      >
                        {(formik) => (
                          <Form>
                            <JoditEditor
                              ref={editor}
                              value={content}
                              tabIndex={1}
                              onBlur={(newContent) => setContent(newContent)}
                              onChange={(newContent) => { }}
                            />
                            <input
                              type="submit"
                              className="btn mt-4 rounded-3 w-20 btn-lg btn-outline-secondary btn-dark"
                              value="Submit"
                            />
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default RRC;
