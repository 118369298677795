import React, { useState } from "react";
import { orderput, updateCartrespone } from "../../services/api";

function CartsPosts({ posts }) {
  const [sta, setSta] = useState("");
  const [responseText, setResponseText] = useState("");
  const [orderDetails, setOrderDetails] = useState({});
  const [itemId, setItemId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [selectedResponse, setSelectedResponse] = useState(null);

  const handleSubmit = async () => {
    try {
      console.log(sta, responseText, userId);
      const data = {
        response: responseText,
        status: sta,
        itemId: itemId,
      };
      const response = await updateCartrespone(userId, data);

      if (response) {
        alert("Data updated successfully.");
        window.location.reload();
      }
    } catch (error) {
      alert("Something went wrong in updating data.");
    }
  };

  const detailsChangeHandler = (p, i, productindex) => {
    setOrderDetails({
      productname: p?.item?.productname1 || "N/A",
      category: p?.item?.categoryid || "N/A",
      skuid: p?.item?.skuid5 || "N/A",
      imported: p?.item?.imported || false,
      dprice: i?.order?.amount || 0,
      paymentid: i?.order?.OrderId || "N/A",
      emailid: i?.user?.email || "N/A",
      address: i?.user?.shippingaddress1 || "N/A",
      mobileno: i?.user?.phoneno || "N/A",
      billingName: i?.order?.BillingName || "N/A",
      shippingaddress: i?.order?.Shippingaddress || "N/A",
      gst: i?.order?.shippingdetail?.[productindex]?.gst || 0,
      shippingCharge:
        i?.order?.shippingdetail?.[productindex]?.shippingCost || 0,
      methodOfShipping:
        i?.order?.shippingdetail?.[productindex]?.shippingType || "N/A",
      NoOfBoxes:
        i?.order?.shippingdetail?.[productindex]?.boxes?.[0]?.boxes || 0,
      finalPrice: i?.order?.amount || 0,
    });
  };

  return (
    <>
      {posts.length > 0 ? (
        posts.map((i, index) =>
          i.cart?.cart?.map((p, productindex) => (
            <tr key={`${index}-${productindex}`}>
              <th>{index + 1}</th>
              <th>{i.user?.fullname || "N/A"}</th>
              <th>{i.user?.phoneno || "N/A"}</th>
              <th>{p?.item?.productname1 || "N/A"}</th>
              <th>{p?.item?.quantity || 0}</th>
              <th>
                <button className="btn btn-success">
                  {new Date(p.addedAt).toLocaleString()}
                </button>
              </th>
              <th>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#detailsModal"
                  onClick={() => detailsChangeHandler(p, i, productindex)}
                >
                  Details
                </button>
              </th>
              <th>{p?.status || "PENDING"}</th>
              <th>
                <button
                  type="button"
                  className="btn btn-info"
                  data-bs-toggle="modal"
                  data-bs-target="#responseModal"
                  onClick={() => setSelectedResponse({ status: p?.status, response: p?.response })}
                >
                  See Response
                </button>
              </th>
              <th>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#updateModal"
                  onClick={() => {
                    console.log(p._id);
                    setItemId(p._id);
                    setUserId(i.cart.userId);
                  }}
                  className="btn btn-warning"
                >
                  Add Response
                </button>
              </th>
            </tr>
          ))
        )
      ) : (
        <tr>
          <td colSpan="11" className="text-center">
            No orders available
          </td>
        </tr>
      )}

      {/* Response Modal */}
      <div
        className="modal fade"
        id="responseModal"
        tabIndex="-1"
        aria-labelledby="responseModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0 shadow-lg rounded-4">
            <div className="modal-header bg-dark text-white rounded-top-4">
              <h5 className="modal-title" id="responseModalLabel">
                💬 Response Details
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body p-4 text-white">
              <div className="mb-4">
                <h6 className="mb-3 text-dark">Current Status</h6>
                <div className={`badge ${
                  selectedResponse?.status === 'active' ? 'bg-success' :
                  selectedResponse?.status === 'hot' ? 'bg-danger' :
                  selectedResponse?.status === 'cold' ? 'bg-info' : 'bg-secondary'
                } fs-6 px-3 py-2`}>
                  {selectedResponse?.status?.toUpperCase() || 'PENDING'}
                </div>
              </div>
              
              <div>
                <h6 className="mb-3 text-dark">Response History</h6>
                <div className="p-3 bg-light rounded">
                  <p className="text-dark mb-0" style={{ whiteSpace: 'pre-line' }}>
                    {selectedResponse?.response || 'No response available'}
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer border-top d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-outline-secondary px-4 rounded-pill"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Details Modal */}
      <div
        className="modal fade"
        id="detailsModal"
        tabIndex="-1"
        aria-labelledby="detailsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0 shadow-lg rounded-4">
            {/* Header */}
            <div className="modal-header bg-dark text-white rounded-top-4">
              <h5 className="modal-title" id="detailsModalLabel">
                📦 Order Details
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
              ></button>
            </div>

            {/* Body */}
            <div className="modal-body p-4 text-white">
              <div className="mb-3">
                <label className="form-label fw-semibold">Product Name</label>
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.productname}
                  readOnly
                />
              </div>
              
              <div className="mb-3">
                <label className="form-label fw-semibold">Category</label>
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.category}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label className="form-label fw-semibold">SKU ID</label>
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.skuid}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label className="form-label fw-semibold">Imported</label>
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.imported ? "Yes" : "No"}
                  readOnly
                />
              </div>
              {/* <div className="mb-3">
                <label className="form-label fw-semibold">Discounted Price</label>
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.dprice}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label className="form-label fw-semibold">Payment ID</label>
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.paymentid}
                  readOnly
                />
              </div> */}
              <div className="mb-3">
                <label className="form-label fw-semibold">Email ID</label>
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.emailid}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label className="form-label fw-semibold">Address</label>
                <input
                  type="text" 
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.address}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label className="form-label fw-semibold">Mobile No</label>
                <input
                  type="text"     
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.mobileno}
                  readOnly
                />  
              </div>
              {/* <div className="mb-3">
                <label className="form-label fw-semibold">Billing Name</label>
                
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.billingName}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label className="form-label fw-semibold">Shipping Address</label>
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.shippingaddress}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label className="form-label fw-semibold">GST</label>
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.gst}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label className="form-label fw-semibold">Shipping Charge</label>
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.shippingCharge}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label className="form-label fw-semibold">Method of Shipping</label>
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.methodOfShipping}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label className="form-label fw-semibold">No of Boxes</label>
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.NoOfBoxes}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label className="form-label fw-semibold">Final Price</label>
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  value={orderDetails.finalPrice}
                  readOnly
                />
              </div> */}
            </div>
            
            {/* Footer */}
            <div className="modal-footer border-top d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-outline-secondary px-4 rounded-pill"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
        

      {/* Update Modal */}
      <div
        className="modal fade"
        id="updateModal"
        tabIndex="-1"
        aria-labelledby="updateModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0 shadow-lg rounded-4">
            {/* Header */}
            <div className="modal-header bg-dark text-white rounded-top-4">
              <h5 className="modal-title" id="updateModalLabel">
                🛠 Update Order Status
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
              ></button>
            </div>

            {/* Body */}
            <div className="modal-body p-4 text-white
            ">
              <div className="mb-3">
                <label className="form-label fw-semibold">Status</label>
                <select
                  className="form-select border-0 shadow-sm"
                  value={sta}
                  onChange={(e) => setSta(e.target.value)}
                >
                  <option value="">Select Status</option>
                  <option value="active">🟢 ACTIVE</option>
                  <option value="hot">🔥 HOT</option>
                  <option value="cold">❄️ COLD</option>
                </select>
              </div>

              <div className="mb-3">
                <label className="form-label fw-semibold">Response</label>
                <textarea
                  className="form-control border-0 shadow-sm"
                  placeholder="Enter response..."
                  rows="4"
                  value={responseText}
                  onChange={(e) => setResponseText(e.target.value)}
                />
              </div>
            </div>

            {/* Footer */}
            <div className="modal-footer  border-top d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-outline-secondary px-4 rounded-pill"
                data-bs-dismiss="modal"
              >
                ❌ Cancel
              </button>
              <button
                type="button"
                className="btn btn-success px-4 rounded-pill ms-2"
                data-bs-dismiss="modal"
                onClick={handleSubmit}
              >
                ✅ Confirm & Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CartsPosts;
