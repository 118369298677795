import React, { useEffect, useState } from "react";
import { Orderget } from "../../services/api";
import Pagination from "../categories/categories/Pagination";
import Footer from "../footer/Footer";
import OrdersPosts from "./OrdersPosts";

function Orders() {
  const [ord, setord] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(25);
  const [searchedvalue, setsearchedvalue] = useState("");
  const [noResults, setNoResults] = useState(false);
  const [sortOrder, setSortOrder] = useState(localStorage.getItem("ordersSortOrder") || "desc");
  const [showImportedOnly, setShowImportedOnly] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(""); // New State for Status Filter

  useEffect(() => {
    async function fetchData() {
      let dat = await Orderget();
      if (dat) {
        setord(dat.data.data.orders);
      } else {
        alert("Something went wrong in getting data.");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem("ordersSortOrder", sortOrder);
  }, [sortOrder]);

  // Filter orders based on imported status & order status
  const filteredOrders = ord
    .filter((order) => {
      if (!showImportedOnly) return true;
      return order.products.some(product => product.imported);
    })
    .filter((order) => (selectedStatus ? order.products.some(product => product.status === selectedStatus) : true));


  const Totalpages = Math.ceil(filteredOrders.length / postsPerPage) || 0;
  const pages = [...Array(Totalpages + 1).keys()].slice(1);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredOrders.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleSortClick = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    const sortedOrders = [...ord].sort((a, b) => {
      const dateA = new Date(a.order.createdAt).getTime();
      const dateB = new Date(b.order.createdAt).getTime();
      return newSortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });

    setSortOrder(newSortOrder);
    setord(sortedOrders);
  };

  const toggleImportedFilter = () => {
    setShowImportedOnly((prev) => !prev);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchedvalue) {
      const dat = await Orderget(searchedvalue);
      if (dat && dat.data.data.orders.length > 0) {
        setord(dat.data.data.orders);
        setNoResults(false);
      } else {
        setord([]);
        setNoResults(true);
      }
    }
  };

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 className="page-title">Orders</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title w-14 mb-4">Order List</h4>
                    <div className="row align-items-center">
                      {/* Search Input and Buttons */}
                      <div className="col-md-6 mt-3">
                        <form className="d-flex mb-2 align-items-center">
                          <input
                            className="form-control me-2"
                            type="search"
                            value={searchedvalue}
                            placeholder="Search Phone Number"
                            onChange={(e) => setsearchedvalue(e.target.value)}
                            aria-label="Search"
                          />
                          <button
                            className="btn btn-dark ms-2"
                            type="submit"
                            onClick={handleSearch}
                          >
                            Search
                          </button>
                        </form>
                      </div>

                      {/* Status Filter Dropdown */}
                      <div className="col-md-3 mt-3">
                        <select
                          className="form-select"
                          value={selectedStatus}
                          onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                          <option value="">All Status</option>
                          <option value="PENDING">Pending</option>
                          <option value="SHIPPED">Shipped</option>
                          <option value="DELIVERED">Delivered</option>
                          <option value="CANCELLED">Cancelled</option>
                          <option value="REFUND">Refund</option>
                          <option value="IN TRANSIT">In Transit</option>
                        </select>
                      </div>

                      {/* Sort and Toggle Buttons */}
                      <div className="col-md-3 mt-3 d-flex justify-content-end align-items-center gap-3">
                        {/* Sort Button */}
                        <button className="btn btn-outline-primary" onClick={handleSortClick}>
                          {sortOrder === "asc" ? "Oldest First" : "Newest First"}
                        </button>

                        {/* Toggle Switch for Imported Filter */}
                        <div className="form-check form-switch d-flex align-items-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="importedToggle"
                            checked={showImportedOnly}
                            onChange={toggleImportedFilter}
                          />
                          <label className="form-check-label ms-2" htmlFor="importedToggle">
                            Show Imported Only
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive">
                      {noResults ? (
                        <div className="text-center mt-4">
                          <p>No user with this phone number.</p>
                        </div>
                      ) : (
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Sr no</th>
                              <th>Products</th>
                              <th scope="col">User Name</th>
                              {/* <th scope="col">Phone no</th> */}
                              <th scope="col">Quantity</th>
                              <th scope="col">Price</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Status</th>
                              <th scope="col" colspan="2">Action</th>
                              <th scope="col">Details</th>
                              <th scope="col">Imported</th>
                              <th scope="col">Date Created</th>
                              <th scope="col">Timestamp</th>
                            </tr>
                          </thead>
                          <tbody>
                            <OrdersPosts posts={currentPosts} />
                          </tbody>
                        </table>
                      )}
                    </div>

                    <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={Totalpages}
                      paginate={paginate}
                      currentPage={currentPage}
                      pageNumbers={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Orders;
