import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import {
  ProductAndVariantget,
  SubSubgetCategory,
  addorupdateHighlight,
  getAllCategory,
  getAllSubCategory,
  getHighlights,
  searchHighlights
} from "../../services/api";
import * as yup from "yup";
import Pagination from "../categories/categories/Pagination";
import "../../App.css";
import HighLightPost from "./Highlidhtpost";

function HighLight() {
  const validate = yup.object({
    category: yup.string().required("Required"),
    product: yup.string().required("Required"),
    subCategory: yup.string(),
    subsubCategory: yup.string(),
    labels: yup.array().of(yup.string().required("Label is required")).min(1, "At least one label is required")
  });

  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [posts, setPosts] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [subcategory, setSubcategory] = useState("");
  const [subsubcategory, setSubsubcategory] = useState("");
  const [category, setCategory] = useState("");
  const [searchedvalue, setsearchedvalue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(25);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch categories
        const categoriesResponse = await getAllCategory();
        setCategories(categoriesResponse);

        // Fetch highlights
        const highlightsResponse = await getHighlights();
        setPosts(highlightsResponse.data || []);
        
        // Fetch products
        const productsResponse = await ProductAndVariantget();
        if (productsResponse?.data) {
          const compressedProducts = [
            ...(productsResponse.data.products || []).map((item) => ({
              productid: item._id,
              productname: item.productname1,
              category: item.categoryid,
              subcategory: item.subcategory,
              subsubcategory: item.subsubcategory,
            })),
            ...(productsResponse.data.variant || []).map((item) => ({
              productid: item._id,
              productname: item.productname1,
              category: item.categoryid,
              subcategory: item.subcategory,
              subsubcategory: item.subsubcategory,
            })),
          ];
          setAllProducts(compressedProducts);
          setProducts(compressedProducts);
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchsubcategories = async () => {
      try {
        const response = await getAllSubCategory();
        if (response) {
          const filtersubcategory = response.filter(
            (item) => item.categoryname.toLowerCase() === category.toLowerCase()
          );
          setSubCategories(filtersubcategory);
          
          if (!editingItem) {
            setSubcategory("");
            setSubsubcategory("");
          }
        }

        if (category) {
          const filterproducts = allProducts.filter(
            (item) => item.category.toLowerCase() === category.toLowerCase()
          );
          setProducts(filterproducts);
        } else {
          setProducts(allProducts);
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };
    if (category) {
      fetchsubcategories();
    }
  }, [category, allProducts, editingItem]);

  useEffect(() => {
    const fetchsubsubcategories = async () => {
      try {
        const response = await SubSubgetCategory();
        if (response) {
          const filtersubsubcategory = response.filter(
            (item) => 
              item.categoryname.toLowerCase() === category.toLowerCase() && 
              item.subcategoryname.toLowerCase() === subcategory.toLowerCase()
          );
          setSubSubCategories(filtersubsubcategory);
          
          if (!editingItem) {
            setSubsubcategory("");
          }
        }

        if (subcategory) {
          const filterproducts = allProducts.filter(
            (item) => 
              item.category.toLowerCase() === category.toLowerCase() && 
              item.subcategory.toLowerCase() === subcategory.toLowerCase()
          );
          setProducts(filterproducts);
        }
      } catch (error) {
        console.error("Error fetching subsubcategories:", error);
      }
    };
    if (subcategory && category) {
      fetchsubsubcategories();
    }
  }, [subcategory, category, allProducts, editingItem]);

  const handleEdit = async (item) => {
    setEditingItem(item);
    setCategory(item.category);
    
    try {
      // Fetch subcategories
      const subCatResponse = await getAllSubCategory();
      if (subCatResponse) {
        const filtersubcategory = subCatResponse.filter(
          (subcat) => subcat.categoryname.toLowerCase() === item.category.toLowerCase()
        );
        setSubCategories(filtersubcategory);
        setSubcategory(item.subCategory);
        
        // Fetch subsubcategories
        const subSubCatResponse = await SubSubgetCategory();
        if (subSubCatResponse) {
          const filtersubsubcategory = subSubCatResponse.filter(
            (subsubcat) => 
              subsubcat.categoryname.toLowerCase() === item.category.toLowerCase() && 
              subsubcat.subcategoryname.toLowerCase() === item.subCategory.toLowerCase()
          );
          setSubSubCategories(filtersubsubcategory);
          setSubsubcategory(item.subsubCategory);
        }
      }
    } catch (error) {
      console.error("Error fetching categories during edit:", error);
    }

    window.scrollTo(0, 0);
  };

  const resetForm = () => {
    setEditingItem(null);
    setCategory("");
    setSubcategory("");
    setSubsubcategory("");
    setSubCategories([]);
    setSubSubCategories([]);
    setProducts(allProducts);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      if (searchedvalue) {
        const response = await searchHighlights(searchedvalue);
        setPosts(response.data || []);
      } else {
        const response = await getHighlights();
        setPosts(response.data || []);
      }
    } catch (error) {
      console.error("Error searching highlights:", error);
    }
  };

  // Calculate pagination
  const Totalpages = Math.ceil(posts.length / postsPerPage);
  const pages = [...Array(Totalpages + 1).keys()].slice(1);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 className="page-title">Highlight</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title mb-4">Highlight</h4>
                    <div className="table-responsive">
                      <Formik
                        initialValues={editingItem || {
                          category: "",
                          subCategory: "",
                          subsubCategory: "",
                          product: "",
                          labels: [""]
                        }}
                        enableReinitialize={true}
                        validationSchema={validate}
                        onSubmit={async (values, actions) => {
                          try {
                            const response = await addorupdateHighlight({
                              ...values,
                              _id: editingItem?._id
                            });

                            if (response.status) {
                              alert(editingItem ? "Updated Successfully" : "Added Successfully");
                              const highlightsResponse = await getHighlights();
                              setPosts(highlightsResponse.data || []);
                              setEditingItem(null);
                              actions.resetForm();
                              resetForm();
                            } else {
                              alert("Something went wrong");
                            }
                          } catch (error) {
                            console.error("Error submitting highlight:", error);
                            alert("Error submitting highlight");
                          }
                        }}
                      >
                        {(formik) => (
                          <Form>
                            <div className="row mt-2 mx-0">
                              <div className="col mt-2">
                                <label>Category Name</label>
                                <Field
                                  as="select"
                                  className={`form-control shadow-none ${
                                    formik.touched.category &&
                                    formik.errors.category &&
                                    "is-invalid"
                                  }`}
                                  onChange={(e) => {
                                    setCategory(e.target.value);
                                    formik.setFieldValue("category", e.target.value);
                                  }}
                                  value={category}
                                  name="category"
                                >
                                  <option value="">Select Category</option>
                                  {categories &&
                                    categories.map((i, index) => (
                                      <option key={index} value={i.title}>
                                        {i.title}
                                      </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                  component="div"
                                  name="category"
                                  className="error"
                                />
                              </div>
                              <div className="col mt-2">
                                <label>Sub Category Name</label>
                                <Field
                                  as="select"
                                  className={`form-control shadow-none ${
                                    formik.touched.subCategory &&
                                    formik.errors.subCategory &&
                                    "is-invalid"
                                  }`}
                                  value={subcategory}
                                  name="subCategory"
                                  onChange={(e) => {
                                    setSubcategory(e.target.value);
                                    formik.setFieldValue("subCategory", e.target.value);
                                  }}
                                >
                                  <option value="">Select Sub Category</option>
                                  {subCategories &&
                                    subCategories.map((i, index) => (
                                      <option key={index} value={i.subcategory}>
                                        {i.subcategory}
                                      </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                  component="div"
                                  name="subCategory"
                                  className="error"
                                />
                              </div>
                              <div className="col mt-2">
                                <label>Sub Sub Category Name</label>
                                <Field
                                  as="select"
                                  className={`form-control shadow-none ${
                                    formik.touched.subsubCategory &&
                                    formik.errors.subsubCategory &&
                                    "is-invalid"
                                  }`}
                                  value={subsubcategory}
                                  name="subsubCategory"
                                  onChange={(e) => {
                                    setSubsubcategory(e.target.value);
                                    formik.setFieldValue("subsubCategory", e.target.value);
                                  }}
                                >
                                  <option value="">Select Sub Sub Category</option>
                                  {subSubCategories &&
                                    subSubCategories.map((i, index) => (
                                      <option key={index} value={i.subsubcategory}>
                                        {i.subsubcategory}
                                      </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                  component="div"
                                  name="subsubCategory"
                                  className="error"
                                />
                              </div>
                              <div className="col mt-2">
                                <label>Product Name</label>
                                <Field
                                  as="select"
                                  className={`form-control shadow-none ${
                                    formik.touched.product &&
                                    formik.errors.product &&
                                    "is-invalid"
                                  }`}
                                  name="product"
                                  value={formik.values.product}
                                  onChange={(e) => {
                                    formik.setFieldValue("product", e.target.value);
                                  }}
                                >
                                  <option value="">Select Product</option>
                                  {products &&
                                    products.map((product, index) => (
                                      <option key={index} value={product.productname}>
                                        {product.productname}
                                      </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                  component="div"
                                  name="product"
                                  className="error"
                                />
                              </div>
                            </div>

                            <div className="row mt-2 mx-0">
                              <div className="col-12">
                                <label>Labels</label>
                                <div className="labels-container">
                                  {formik.values.labels.map((label, index) => (
                                    <div key={index} className="d-flex mb-2">
                                      <Field
                                        type="text"
                                        name={`labels.${index}`}
                                        className={`form-control shadow-none ${
                                          formik.touched.labels?.[index] &&
                                          formik.errors.labels?.[index] &&
                                          "is-invalid"
                                        }`}
                                        placeholder="Enter label (e.g., color, size)"
                                      />
                                      {formik.values.labels.length > 1 && (
                                        <button
                                          type="button"
                                          className="btn btn-danger ml-2"
                                          onClick={() => {
                                            const newLabels = [...formik.values.labels];
                                            newLabels.splice(index, 1);
                                            formik.setFieldValue("labels", newLabels);
                                          }}
                                        >
                                          Remove
                                        </button>
                                      )}
                                    </div>
                                  ))}
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      formik.setFieldValue("labels", [
                                        ...formik.values.labels,
                                        ""
                                      ]);
                                    }}
                                  >
                                    Add Label
                                  </button>
                                </div>
                                <ErrorMessage
                                  component="div"
                                  name="labels"
                                  className="error"
                                />
                              </div>
                            </div>

                            <div className="mt-3">
                              <button
                                type="submit"
                                className="btn btn-primary mr-2"
                              >
                                {editingItem ? "Update" : "Submit"}
                              </button>
                              {editingItem && (
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    resetForm();
                                    formik.resetForm();
                                  }}
                                >
                                  Cancel
                                </button>
                              )}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title mb-4">Highlights List</h4>
                    <form
                      className="d-flex mb-2"
                      style={{ width: "50%" }}
                      role="search"
                      onSubmit={handleSearch}
                    >
                      <div className="btn-group">
                        <input
                          className="form-control mx-2 btn-close"
                          type="search"
                          value={searchedvalue}
                          placeholder="Search highlights"
                          onChange={(e) => setsearchedvalue(e.target.value)}
                          aria-label="Search"
                        />
                        <button
                          type="button"
                          className="btn bg-transparent"
                          style={{ left: "-43px" }}
                          onClick={async () => {
                            const response = await getHighlights();
                            setPosts(response.data || []);
                            setsearchedvalue("");
                          }}
                        >
                          <i className="fa fa-times" style={{ color: "white" }}></i>
                        </button>
                        <button
                          className="btn rounded btn-md btn-outline-secondary btn-dark"
                          type="submit"
                        >
                          Search
                        </button>
                      </div>
                    </form>

                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Sr No.</th>
                            <th scope="col">Category</th>
                            <th scope="col">Sub Category</th>
                            <th scope="col">Sub Sub Category</th>
                            <th scope="col">Product</th>
                            <th scope="col">Labels</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <HighLightPost
                            posts={currentPosts}
                            onEdit={handleEdit}
                          />
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={Totalpages}
                      paginate={(pageNumber) => setCurrentPage(pageNumber)}
                      currentPage={currentPage}
                      pageNumbers={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HighLight;
